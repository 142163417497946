import { Logout } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";
import classNames from "classnames";
import React from "react";
import { useSession } from "~/hooks/useSession";
import { useI18n } from "~/lib/i18n/use-i18n";
import { useStopCompanyImpersonationMutation } from "~/pages/api/auth/company-impersonation/stop-company-impersonation";
import { useStopUserImpersonationMutation } from "~/pages/api/auth/user-impersonation/stop-user-impersonation";
import { useRelinquishTemporarySuperAdminRightsMutation } from "~/pages/api/user/relinquish-temporary-super-admin-rights";
import { formatUserFullName } from "~/services/user/utils";

type Props = {
  className?: string;
};

export const useShouldDisplayNotices = () => {
  const { impersonatingCompany, impersonatingUser, user } = useSession();

  return impersonatingCompany || impersonatingUser || user?.isSuperAdmin;
};

export const UserNotices: React.FC<Props> = ({ className }) => {
  const { t } = useI18n();
  const { impersonatingUser, impersonatingCompany, user } = useSession();
  const shouldDisplayNotices = useShouldDisplayNotices();

  const stopUserImpersonationMutation = useStopUserImpersonationMutation();
  const stopCompanyImpersonationMutation = useStopCompanyImpersonationMutation();
  const relinquishTemporarySuperAdminRightsMutation = useRelinquishTemporarySuperAdminRightsMutation();

  if (!shouldDisplayNotices || !user) {
    return null;
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      className={classNames(className, "px-4 text-white", {
        "bg-amber-500": user.company.demo,
        "bg-secondary-500": !user.company.demo,
      })}
      spacing={2}
    >
      {impersonatingUser && (
        <>
          <Typography variant="h4" color="white">
            {t("components.layout.user-notices", { userName: formatUserFullName(user) })}
          </Typography>

          {!impersonatingCompany && (
            <IconButton onClick={() => stopUserImpersonationMutation.mutate({})}>
              <Logout className="text-white" fontSize="small" />
            </IconButton>
          )}
        </>
      )}

      {!impersonatingUser && impersonatingCompany && (
        <>
          <Typography variant="h4" color="white">
            You're currently impersonating {user.company.name} (#{user.companyId})
          </Typography>

          <IconButton onClick={() => stopCompanyImpersonationMutation.mutate({})}>
            <Logout className="text-white" fontSize="small" />
          </IconButton>
        </>
      )}

      {impersonatingUser && impersonatingCompany && (
        <>
          <Typography variant="h4" color="white">
            @ {user.company.name} (#{user.companyId})
          </Typography>

          <IconButton onClick={() => stopUserImpersonationMutation.mutate({})}>
            <Logout className="text-white" fontSize="small" />
          </IconButton>
        </>
      )}

      {!impersonatingCompany && user.isSuperAdmin && (
        <>
          <Typography variant="h4" color="white">
            You're currently using super admin privileges.
          </Typography>

          <Typography
            className="cursor-pointer underline"
            variant="h4"
            color="white"
            onClick={() => relinquishTemporarySuperAdminRightsMutation.mutate({})}
          >
            Relinquish them
          </Typography>
        </>
      )}
    </Stack>
  );
};
